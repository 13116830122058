import React from 'react';
import { Link } from 'react-router-dom';

import './Navbar.css';

export default function Navbar() {
  return (
    <nav className='navbar'>
      <ul>
        <li className='title'>
          <Link to='/'>Laobot games</Link>
        </li>
        <li>
          <Link to='/login'>Login</Link>
          <Link to='/contato'>Contact</Link>
          <Link to='/sobre'>About</Link>
        </li>
      </ul>
    </nav>
  );
}
